<div class="containter main-container">
</div>

<div class="jumbotron">
  <div class="container text-center">
    <h1 class="display-5">Professional, Personal Solutions</h1>
    <p class="text-muted">Bring your ideas to life!</p>
    <p><a class="btn btn-primary btn-lg" role="button" href="contact-us">Learn more »</a></p>
  </div>
</div>

<div class="text-center container pl-5 pr-5">
  <h2 class="mb-5 text-muted">About Us</h2>
  <div class="row">
    <div class="mb-4">
      <h4>Personalized Software</h4>
      <p class="text-muted">
        At our company, we turn the digital dreams of our clients into a reality.
        We work closely with our users throughout development to ensure that we
        are still aligned with the end-goal. We are committed to producing exceptional
        software for each of our clients.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6">
      <h4>Rigorous Testing</h4>
      <p class="text-muted">
        Once we have finished the initial development of our client's product,
        we begin performing meticulous bug checks. These tests are included in the
        price of the development package. After testing, we deliver the software to our clients.
      </p>
    </div>
    <div class="col-12 col-sm-6">
      <h4>Quality Guaranteed</h4>
      <p class="text-muted">
        The world of technology can be fast-paced and scary. That's why our goal is to
        provide a high-quality product that aligns with your company's needs.
        No matter the budget, we pride ourselves on providing professional customer service.
        We guarantee you will be satisfied with our work.
      </p>
    </div>
  </div>
</div>
