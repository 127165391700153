<nav class="navbar navbar-expand-md navbar-dark bg-dark">
  <a class="navbar-brand text-muted" (click)="navigateToHome()" href="">PENCA SOFTWARE</a>
  <button type="button" class="btn btn-outline-primary navbar-toggler" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="collapse navbar-collapse">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" (click)="isCollapsed = true; navigateToHome()" href="">HOME</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="isCollapsed = true; navigateToAboutUs()" href="contact-us">CONTACT US</a>
      </li>
    </ul>
<!--     <div class="navbar-nav ml-auto">
      <a class="nav-link btn btn-sm" (click)="logIn()">LOG IN</a>
    </div> -->
  </div>

</nav>
