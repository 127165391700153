import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Email } from '../model/Email';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  constructor(private dataservice: DataService) { }

  emailForm: Email;
  message: string;
  loading = false;

  ngOnInit(): void {
    this.emailForm = new Email();
  }

  onSubmit(formElement){
    console.log(formElement)
    if(formElement.valid){
      this.loading = true;
      this.dataservice.sendEmail(this.emailForm).subscribe(
        next => {
          formElement.resetForm();
          this.loading = false;
          this.emailForm = new Email();
          this.message = 'Message sent!'
        }
      );
    }
  }
}
