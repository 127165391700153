import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { Email } from './model/Email';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  sendEmail(email: Email): Observable<any>{
    return this.http.post<Email>(environment.restUrl+'/contact-us', email);
  }
}
