import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public isCollapsed = true;

  constructor() { }

  ngOnInit(): void {
  }

  navigateToContactUs(){
    // alert('not implemented');
  }

  navigateToAboutUs(){
    // alert('not implemented');
  }

  navigateToHome(){
  }
  isLoggedIn(){
  }

  logOut(){
  }

  logIn(){
  }
}
