<div class="text-center container pl-5 pr-5">
  <h2 class="mb-5 text-muted mt-5 ">Contact Us</h2>
</div>
<div class="container pl-5 pr-5 mb-4">
  <div class="row">
    <form class="col-12" autocomplete="off" (ngSubmit)="onSubmit(formElement)" #formElement="ngForm">
      <div class="mb-3">
        <label for="contactName" class="form-label">Name <span class="text-muted">(Optional)</span></label>
        <input type="text" class="form-control" id="contactName" placeholder="Name" [(ngModel)]="emailForm.name" name="name" #name="ngModel" maxlength="50">

        <div *ngIf="formElement.submitted && name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
          <div *ngIf="name.errors.maxlength">
            Name must max length is 50 characters long
          </div>
        </div>

      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Email*</label>
        <input type="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="email@company.com" [(ngModel)]="emailForm.email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
          name="email" #email="ngModel" maxlength="100" required>
        <div id="emailHelp" class="form-text text-muted ">We'll never share your email with anyone else.</div>

        <div *ngIf="formElement.submitted && email.invalid && (email.dirty || email.touched)" class="alert alert-danger">

          <!-- {{email.errors | json}} -->
          <div *ngIf="email.errors.pattern">
            Invalid Email
          </div>
          <div *ngIf="email.errors.required">
            Email is required
          </div>
          <div *ngIf="email.errors.maxlength">
            Email max length is 50 characters long
          </div>
<!--           <div *ngIf="email.errors.minlength">
            Email must be at least 3 characters long
          </div> -->
        </div>
      </div>
      <div class="mb-3">
        <label for="projectDescription" class="form-label">Project Description*</label>
        <textarea class="form-control" id="projectDescription" rows="7" aria-multiline="true" required placeholder="Tell us about your project" [(ngModel)]="emailForm.desc" name="desc" maxlength='500' minlength="5" #desc="ngModel"></textarea>

        <div *ngIf="formElement.submitted && desc.invalid && (desc.dirty || desc.touched)" class="alert alert-danger">

          <div *ngIf="desc.errors.required">
            Product description is required
          </div>
          <div *ngIf="desc.errors.maxlength">
            Product description max length is 500 characters long
          </div>
          <div *ngIf="desc.errors.minlength">
            Product description must be at least 5 characters long
          </div>
        </div>

      </div>
      <button type="submit" class="btn btn-primary" *ngIf="!loading && !message">Submit</button>
      <div class="spinner-border text-primary" role="status" *ngIf="loading">
       <!--  <span class="visually-hidden">Loading...</span> -->
      </div>
      <div class="alert alert-primary" role="alert" *ngIf="message">
        {{message}}
      </div>
    </form>


  </div>
</div>
